import React, { FC } from 'react';

import WorkPageTemplate from '../../atomic/templates/WorkPageTemplate';

const pageData = {
  header: {
    title: 'Carmines',
    useCases: 'INTERIOR DESIGN,BRAND DEVELOPMENT',
    description: `Developing the branding for Carmines has to be the most personal design work I have worked on. With their new restaurant opening in Bellevue Washington. CJ Smeraldo the owner and good friend of mine requested that I develop their new branding for the Carmines restaurant. With his father and restaurant founder passing away some years ago it was an honor to be apart of this experience.

    I wanted a minimalistic approach to the branding, I combined Carmines signature with his two sons, CJ and Phillip to create the Carmines signature. For the exterior signage I took the finish from the interior pizza oven and the black metal from the buildings exterior as the finishes to the sign. This combination created a simple and memorable first experience upon entering the restaurant.

    Also the food is amazing!`,
  },
  caseId: 'carmines',
  contents: [
    'image0.jpg',
    'image1.jpg',
    'image2.jpg',
    'image3.jpg',
    'image4.jpg',
    'image5.jpg',
    'image6.jpg',
    'image7.jpg',
    'image8.jpg',
    'image9.jpg',
    'image10.jpg',
    'image11.jpg',
    'image12.jpg',
    'image13.jpg',
    'image14.jpg',
    'image15.jpg',
    'image16.jpg',
    'image17.jpg',
    'image18.jpg',
  ],
};

export default (() => <WorkPageTemplate data={pageData} />) as FC;
